import { FC } from "react";
import styles from './field-schema.module.scss'
import squad from '../../real_madrid_squad.json'

const FieldSchema:FC = () => {

    let lines = [];

    for (let i = 0; i < 8; i++) {
        let top = i * 100; // Увеличиваем значение top на 100 пикселей для каждой линии
        lines.push(<div key={i} className={styles.line} style={{ top: `${top}px` }}></div>);
    }

    function convertSquadToPlayerList(squad:any) {
        //@ts-ignore
        const players = [];
    
        for (const category in squad) {
            //@ts-ignore
            squad[category].forEach(player => {
                players.push(player);
            });
        }
        //@ts-ignore
        return players;
    }

    const players = convertSquadToPlayerList(squad.squad)
    console.log(players)

    

    return (
        <div className={styles.field}>
            <div className={styles.field_top}>
                <div className={styles.corner} style={{borderBottom: "2px solid white", borderRight: "2px solid white", borderRadius: '0 0 100%'}}/>
                <div className={styles.penalty_area}>
                    <div className={styles.penalty_area_dop}/>
                </div>
                <div className={styles.corner} style={{borderBottom: "2px solid white", borderLeft: "2px solid white", borderRadius: '0 100% 0'}}/>
            </div>
            <div className={styles.field_bottom}>
                <div className={styles.corner} style={{borderBottom: "2px solid white", borderRight: "2px solid white", borderRadius: '0 0 100%'}}/>
                <div className={styles.penalty_area}>
                    <div className={styles.penalty_area_dop}/>
                </div>
                <div className={styles.corner} style={{borderBottom: "2px solid white", borderLeft: "2px solid white", borderRadius: '0 100% 0'}}/>
            </div>
            {lines}
            <div className={styles.team_schema}>
                <div className={styles.goalkeeper}>
                    <div className={styles.player_ball}>
                            <div className={styles.number}>{squad.squad.goalkeeper[0].number}</div>
                    </div>
                </div>
                <div className={styles.defending}>
                    {squad.squad.defenders.map( it => 
                        <div className={styles.player_ball}>
                            <div className={styles.number}>{it.number}</div>
                        </div>
                    )}
                </div>
                <div className={styles.midfield}>
                    {squad.squad.midfielders.map( it => 
                        <div className={styles.player_ball}>
                            <div className={styles.number}>{it.number}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FieldSchema