export const ROUTER_PATHS = {
    HOME: '/',
    CHART: 'chart/:id',
    FAQ: '/faq',
    BEST: 'best',
    CONTACTS: '/contacts',
    LIVE: 'live',
    COMMUNITY: 'community',
    NEWS: 'news',
    NEWS_ITEM: 'news/:slug',
    REGISTER: 'register',
    LOGIN: 'login',
    PAYMENT_SUCCESS: 'pay/success-payment',
    PAYMENT_FAILED: 'pay/success-payment',
    RESTORE_PASSWORD: 'restore-password',
  } as const;