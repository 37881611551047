import React from 'react';
import styles from './Best.module.scss'
import WorkInProgress from '../../components/WorkInProgress/WorkInProgress';
import FieldSchema from '../../components/field-schema/field-schema';

const Best = () => {
    return (
        <div className={styles.best}>
            
            <FieldSchema/>

            {/* <WorkInProgress/> */}
        </div>
    );
};

export default Best;